.sd-item {
  padding: calcSize(1.5) 0;
}

.sd-item--disabled.sd-item--disabled .sd-item__decorator,
.sd-item__decorator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calcSize(3);
  height: calcSize(3);
  box-sizing: border-box;
  background: var(--sd-item-default-background, $editor-background);
  box-shadow: inset 0px 1px 2px $shadow-inner;
  border: none;
  flex-shrink: 0;
}

.sd-item--checked .sd-item__decorator {
  box-shadow: none;
}

.sd-item__control:focus + .sd-item__decorator,
.sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
  box-shadow: 0 0 0 2px $primary;
  outline: none;
}

.sd-item__control:focus + .sd-item__decorator,
.sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
  background: $background;
}

.sd-item--checked .sd-item__decorator {
  background: $primary;
}

.sd-item__control-label {
  font-family: var(--font-family, $font-family);
  font-style: normal;
  font-weight: normal;
  line-height: calcSize(3);
  font-size: calcSize(2);
  color: $foreground;
  white-space: normal;
  word-break: break-word;
  width: 100%;
  text-align: left;
}

.sd-item--disabled .sd-item__control-label {
  color: $foreground;
  opacity: 0.25;
}

.sd-root--readonly .sd-item--disabled .sd-item__control-label {
  color: $foreground;
}

.sd-item--error .sd-item__decorator {
  background: $red-light;
}
